var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"id":'input-group-' + _vm.id,"description":_vm.description
        ? _vm.description
        : `Min Image dimensions : ${_vm.width} px X ${_vm.height} px`,"label-for":'input-' + _vm.id,"label-cols-md":_vm.labelCols || 2,"content-cols-lg":_vm.contentCols || 4},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.$attrs.hasOwnProperty('required') && _vm.$attrs.required != null)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]},proxy:true}])},[_c('b-form-file',{ref:`input-file-${_vm.id}`,class:_vm.classComponent,attrs:{"id":'input-file-' + _vm.id,"state":_vm.state,"accept":"image/*","disabled":_vm.disabled},on:{"input":function($event){return _vm.setPreviewImage($event)}}}),(_vm.invalidMessage && _vm.invalidMessage.length > 0)?_c('div',{staticClass:"invalid-feedback text-right"},_vm._l((_vm.invalidMessage),function(message,index){return _c('div',{key:_vm.id + '-' + message + '-' + index},[_vm._v(" "+_vm._s(message)+" ")])}),0):_vm._e()],1),(!_vm.imageSrc && _vm.src)?_c('div',{staticClass:"row mb-3"},[_c('div',{class:`col-md-${_vm.cropperCols || _vm.contentCols} offset-md-${_vm.labelCols || 2} mt-2`},[_c('img',{staticClass:"img-preview img-fluid w-100",attrs:{"src":_vm.src,"alt":_vm.label}})])]):(_vm.imageSrc)?_c('div',{staticClass:"row"},[_c('div',{class:`col-md-${_vm.cropperCols || _vm.contentCols || 4} offset-md-${_vm.labelCols || 2} mt-2`},[_c('b-img',{class:{
          'd-none': (_vm.image.width > _vm.width || _vm.image.height > _vm.height) && !_vm.imageSrc.includes('image/gif')
        },attrs:{"src":_vm.imageSrc,"fluid":""}}),_c('vue-cropper',{ref:_vm.type,staticClass:"collection-img-cropper",class:{
          'd-none': !_vm.imageSrc || _vm.imageSrc.includes('image/gif') || _vm.image.width == _vm.width && _vm.image.height == _vm.height
        },attrs:{"src":_vm.imageSrc,"alt":_vm.label,"responsive":true,"viewMode":2,"aspect-ratio":_vm.width / _vm.height,"zoomable":false,"autoCropArea":1},on:{"cropend":function($event){return _vm.setImage()}}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }