<template>
  <div class="mb-3">
    <b-form-group
      :id="'input-group-' + id"
      :description="
        description
          ? description
          : `Min Image dimensions : ${width} px X ${height} px`
      "
      :label-for="'input-' + id"
      :label-cols-md="labelCols || 2"
      :content-cols-lg="contentCols || 4"
      class="mb-0"
    >
      <template #label>
        {{ label }} <span v-if="$attrs.hasOwnProperty('required') && $attrs.required != null" class="text-danger">*</span>
      </template>
      <b-form-file
        :id="'input-file-' + id"
        :state="state"
        accept="image/*"
        @input="setPreviewImage($event)"
        :disabled="disabled"
        :class="classComponent"
        :ref="`input-file-${id}`"
      ></b-form-file>
      <div
        class="invalid-feedback text-right"
        v-if="invalidMessage && invalidMessage.length > 0"
      >
        <div
          v-for="(message, index) in invalidMessage"
          :key="id + '-' + message + '-' + index"
        >
          {{ message }}
        </div>
      </div>
    </b-form-group>
    <div class="row mb-3" v-if="!imageSrc && src">
      <div :class="`col-md-${cropperCols || contentCols} offset-md-${labelCols || 2} mt-2`">
        <img class="img-preview img-fluid w-100" :src="src" :alt="label" />
      </div>
    </div>
    <div class="row" v-else-if="imageSrc">
      <div :class="`col-md-${cropperCols || contentCols || 4} offset-md-${labelCols || 2} mt-2`">
        <b-img :class="{
            'd-none': (image.width > width || image.height > height) && !imageSrc.includes('image/gif')
          }"
          :src="imageSrc"
          fluid
        />
        <vue-cropper
          :class="{
            'd-none': !imageSrc || imageSrc.includes('image/gif') || image.width == width && image.height == height
          }"
          :ref="type"
          :src="imageSrc"
          :alt="label"
          :responsive="true"
          :viewMode="2"
          :aspect-ratio="width / height"
          :zoomable="false"
          class="collection-img-cropper"
          :autoCropArea="1"
          @cropend="setImage()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "MyInputFormImageCropper",
  props: [
    "id",
    "value",
    "src",
    "label",
    "placeholder",
    "description",
    "classComponent",
    "type",
    "invalidMessage",
    "disabled",
    "state",
    "width",
    "height",
    "imageName",
    "maxFileSize", //* value must be in KB (Kilo Bytes)
    "labelCols",
    "contentCols",
    "cropperCols"
  ],
  data() {
    return {
      image: {},
      imageSrc: null,
    };
  },
  computed: {
    dataImageName() {
      return this.imageName;
    },
  },
  methods: {
    setPreviewImage(e) {
      if (!e) return; //? Prevent console error on cancel button when select image file
      try {
        const file = e;
        const maxFileSize = this.maxFileSize || 500; //? Default max file size is 500kb
        if (Math.round(file.size / 1000) > maxFileSize) return this.$helpers.toaster.make(`File size must not exceed ${maxFileSize} KB`, "danger"); //* Image file size validation
        if (!file.type.includes("image/")) return this.$helpers.toaster.make( "Please select an image file (JPG, JPEG, PNG, GIF)", "danger"); //* File type validation
        if (file.type.includes("image/svg")) return this.$helpers.toaster.make("Image format cannot be SVG", "danger"); //* Prevent image with SVG format

        // check if file reader is supported
        if (typeof FileReader === "function") {
          const img = new Image();
          let imgWidth, imgHeight;
          let popToast = false;
          img.src = window.URL.createObjectURL(file);
          img.onload = (e) => {
            if (e["path"]) {
              // Chromium
              imgWidth = e["path"][0].naturalWidth;
              imgHeight = e["path"][0].naturalHeight;
            } else if (e["originalTarget"]) {
              // Firefox
              imgWidth = e["originalTarget"].naturalWidth;
              imgHeight = e["originalTarget"].naturalHeight;
            } else {
              imgWidth = img.width;
              imgHeight = img.height;
            }

            //? Image width dimension validation
            if (imgWidth < this.width) {
              this.$helpers.toaster.make(`Image width must be at least ${this.width} px`, "danger");
              popToast = true;
            }

            //? Image height dimension validation
            if (imgHeight < this.height) {
              this.$helpers.toaster.make(`Image height must be at least ${this.height} px`, "danger");
              popToast = true;
            }

            if (popToast) return;

            const reader = new FileReader();
            reader.onload = (event) => {
              this.imageSrc = event.target.result;
              //* $emit input file (if image format is GIF)
              if (event.target.result.includes("image/gif")) this.returnModel();
              //* Set cropped image before $emit input file (if image format either PNG, JPG, JPEG) 
              else {
                this.replaceCropperImageUrl(event.target.result); //? NEW WAY (run replaceCropperImageUrl until ref is exist)
                this.setImage();
              }
            };
            reader.readAsDataURL(file);
            img.width = imgWidth
            img.height = imgHeight
            this.image = img
          };
        }
      } catch (error) {
        console.error(error);
      }
    },
    replaceCropperImageUrl(src) {
      const me = this;
      //? Handling cropper.replace() not a function on vue cropper ready state
      if (this.$refs[this.type] === undefined) return setTimeout(() => { me.replaceCropperImageUrl(src) }, 1000);
      this.$refs[this.type].replace(src);
    },
    async returnModel() {
      const file = await fetch(this.imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const name = this.$helpers.format.slug(this.dataImageName ?? this.type);
          const file = new File([blob], name + "." + blob.type.split("/")[1], blob);
          return file;
        });
      console.log("file: ", file);
      this.$emit("input", file)
    },
    setImage() {
      //? Handling cropper.getCroppedCanvas() not a function on vue cropper ready state
      const me = this;
      if (!this.$refs[this.type]?.getCroppedCanvas()) return setTimeout(() => { me.setImage() }, 500);

      const imageType = document.querySelector(`#input-file-${this.id}`).files[0].type;
      this.$refs[this.type]
        .getCroppedCanvas({
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        })
        .toBlob(blob => {
          const name = this.$helpers.format.slug(this.dataImageName ?? this.type);
          const file = new File([blob], name + "." + blob.type.split("/")[1], blob);
          this.$emit('input', file)
        }, imageType, .85);
    },
  },
  watch: {
    value(val) {
      if (val) return 
      // Reset Image Cropper if props 'value' is null
      this.$refs[`input-file-${this.id}`].reset();
      this.$refs[this.type].destroy();
      this.imageSrc = null;
      this.image = null;
    },
  }
};
</script>

<style scoped>
/* .preview-image {
  object-fit: contain;
} */
/* custom small text info for form */
/* .custom-text-info {
  color: grey;
  font-style: italic;
} */
/* Custom image style for vue cropper */
.collection-img-cropper >>> img {
  border: 1px solid #ced4da;
}
</style>